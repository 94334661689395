import {firestore, messaging} from "@/firebase/index.js"
import {getToken, isSupported, onMessage} from "firebase/messaging"
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore"

export function useFirebaseMessaging(args) {
  async function initMessaging() {
    if (!isSupported()) {
      console.warn("firebase messaging is not supported by this browser")
      return
    }
    if (Notification.permission == "granted") {
      // console.log("notification permission was granted")

      await registerMessaging()

      // console.log("listening to messages")
      onMessage(messaging, () => {
        // console.log("Message received. ", payload)
      })
    } else {
      console.log("notification permission was not granted (yet)")
    }
  }
  async function registerMessaging() {
    // console.log("registerMessaging()")

    // register custom service worker
    let serviceWorkerPath = "/js/firebaseMessagingSW.js"
    let registeredServiceWorkers = await navigator.serviceWorker.getRegistrations()
    let serviceWorkerRegistration = registeredServiceWorkers.find((sw) =>
      sw.active.scriptURL.endsWith(serviceWorkerPath)
    )

    if (!serviceWorkerRegistration) {
      serviceWorkerRegistration = await navigator.serviceWorker.register(serviceWorkerPath)
      // console.log("registered new service worker:", serviceWorkerRegistration)
    } else {
      // console.log("already have service worker running")
    }

    // console.log("auth:", auth.currentUser)

    const vapidKey = import.meta.env.VITE_FIREBASE_MESSAGING_VAPID_KEY

    let token = await getToken(messaging, {vapidKey, serviceWorkerRegistration})

    // let token
    if (token) {
      // console.log("client token:")
      // console.log(token)

      await saveUserToken(token)
    } else {
      console.warn("no token available, request permission to generate")
    }

    return
  }

  async function saveUserToken(token) {
    let tokenAlreadySaved = await isTokenAlreadySaved(token)

    if (tokenAlreadySaved) {
      await updateUserToken(tokenAlreadySaved)
    } else {
      const fbMessagingTokensRef = collection(firestore, `users/${args.userId}/fbMessagingTokens`)
      const data = {
        token,
        meta: {
          created: serverTimestamp(),
          updated: false,
          deleted: false,
        },
      }
      await addDoc(fbMessagingTokensRef, data).catch((e) =>
        console.error("error saving user token:", e)
      )
    }

    return
  }

  async function updateUserToken(tokenAlreadySaved) {
    const tokenRef = doc(firestore, `users/${args.userId}/fbMessagingTokens/${tokenAlreadySaved}`)
    const tokenData = {
      "meta.updated": serverTimestamp(),
    }
    await updateDoc(tokenRef, tokenData)
  }

  async function isTokenAlreadySaved(token) {
    const collectionRef = collection(firestore, `users/${args.userId}/fbMessagingTokens`)
    const q = query(collectionRef, where("token", "==", token))
    let foundToken = await getDocs(q)
    if (foundToken.docs.length) {
      return foundToken.docs[0].id
    } else {
      return false
    }
  }

  return {initMessaging}
}
